import './App.css';
import React, { useState } from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const serviceMail = 'mailto:business@gameman.co';

function App() {
  const [userName, setUserName] = useState('');
  const [eMail, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [errorMessage, seterrorMessage] = useState('');
  // 手機菜單不切換路由，只在當前頁利用錨點定位時發生
  // <a><label><label/><a/>, 這樣寫才能按下菜單同時關閉菜單
  // 但會造成html的錨點失效，故用js操作錨點
  // function handleAnchor(e) {
  //   window.location.href = e;
  // } 
  //送出聯絡我的表單
  function handleClick(){
    if(userName === ''){
      const modal = document.getElementById("myModal");
      modal.style.display = "flex";
      seterrorMessage('Name can not empty');
      return;
    }
    if(eMail === ''){
      const modal = document.getElementById("myModal");
      modal.style.display = "flex";
      seterrorMessage('Email can not empty');
      return;
    }
    if(phone === ''){
      const modal = document.getElementById("myModal");
      modal.style.display = "flex";
      seterrorMessage('Phone number can not empty');
      return;
    }
    window.location.href = serviceMail;
  }
  //確認關閉按鈕
  function handleClose(){
    const modal = document.getElementById("myModal");
    modal.style.display = "none";
  }
  //app store
  function handleGoIOS(){
    const modal = document.getElementById("myModal");
    modal.style.display = "flex";
    seterrorMessage('Coming soon');
  }
  //手機菜單按li時要有作用，暫時先用js處理，日後再修改
  function handleMenuli(){
    document.getElementById("hamBurger_control").checked = false;
    
  }
  //slider setting
  const settings = {
      // arrows:false,
      // dots: true,
      // infinite: true,
      // speed: 500,
      // slidesToShow: 1,
      // slidesToScroll: 1,
      // variableWidth: true,
      // // adaptiveHeight: true,

      className:"slider",
      arrows:false,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
  };
  
  return (
    <div className='wrap'>
      <input type="checkbox" id='hamBurger_control' />
      <input type="checkbox" id='dropDown_control' />
      <div className='header' >
        <nav>
          <ul>
            {/* 原本只按a有作用是這種寫法，但改成按下li時也要也作用，需要將li的寫法改成無需js的寫法 */}
            {/* <li><a href="/#" onClick={() => handleAnchor('#GAME_INTRODUCTION')}><label htmlFor="hamBurger_control">GAME INTRODUCTION</label></a></li> */}
            {/* <li ><a href="/#">雙層菜單</a><label htmlFor="dropDown_control"><span id='dropDown_button' className='dropDown_button'>展開按鈕</span></label>
              <ul>
                <li><a href="#menu2_1" onClick={() => handleAnchor('#menu2_1')}><label htmlFor="hamBurger_control">子菜單一</label></a></li>
                <li><a href="/#menu2_2">子菜單二</a></li>
                <li><a href="/#">子菜單三</a></li>

              </ul>
            </li> */}
            {/* <li><a href="/#" onClick={() => handleAnchor('#GAME_FEATURES')}><label htmlFor="hamBurger_control">GAME FEATURES</label></a></li> */}
            {/* <li><a href="/#" onClick={() => handleAnchor('#ABOUT_US')}><label htmlFor="hamBurger_control">ABOUT US</label></a></li> */}
            {/* <li><a href="/#" onClick={() => handleAnchor('#CONTACT_US')}><label htmlFor="hamBurger_control">CONTACT US</label></a></li> */}
            <li onClick={handleMenuli}><a href="/#GAME_INTRODUCTION">GAME INTRODUCTION</a></li>
            <li onClick={handleMenuli}><a href="/#GAME_FEATURES">GAME FEATURES</a></li>
            <li onClick={handleMenuli}><a href="/#ABOUT_US">ABOUT US</a></li>
            <li onClick={handleMenuli}><a href="/#CONTACT_US">CONTACT US</a></li>
            
          </ul>
        </nav>
        <label htmlFor="hamBurger_control" className='hamburger_button'>

          <span id="line1" className='line1Close'></span>
          <span id="line2" className='line2Close'></span>
          <span id="line3" className='line3Close'></span>

        </label>
      </div>
      <div className='main'>
        <div id='/#' className='item'>
            <img src={require("./images/01_download/p1_bg.jpg")}  alt="" />
            <Slider {...settings}>
              <div>
                {/* <img src={require("./images/01_download/01.gamemap/01.png")} alt="" /> */}
                <img src={require("./images/01_download/01.gamemap/gamemap_p1.png")} alt="" />
              </div>
              <div>
              {/* <img src={require("./images/01_download/01.gamemap/02.png")} alt="" /> */}
              <img src={require("./images/01_download/01.gamemap/gamemap_p2.png")} alt="" />
              </div>
              <div>
              {/* <img src={require("./images/01_download/01.gamemap/03.png")} alt="" /> */}
              <img src={require("./images/01_download/01.gamemap/gamemap_p3.png")} alt="" />
              </div>
              <div>
              {/* <img src={require("./images/01_download/01.gamemap/04.png")} alt="" /> */}
              <img src={require("./images/01_download/01.gamemap/gamemap_p4.png")} alt="" />
              </div>
              
            </Slider>
            <div className='sliderTitle'>
               <img src={require("./images/01_download/title_p1.png")}  alt="" />
            </div>
            <div className='sliderFooter'>
            <button className='btn' onClick={handleGoIOS}><img src={require("./images/01_download/btn_appstore.png")} alt="" /></button>
            <a href="https://play.google.com/store/apps/details?id=company.snake.game" className='btn'><img src={require("./images/01_download/btn_googleplay.png")} alt="" /></a>
              
            </div>
        </div>
        <div id='GAME_INTRODUCTION' className='itemMarginTop'>
          <img src={require("./images/02_introduction/p2_introduction.jpg")} alt="" />
        </div>
        <div id='GAME_FEATURES' className='item'>
          <img src={require("./images/03_features/03_features.jpg")} alt="" />
        </div>
        <div id='ABOUT_US' className='item'>
          <img src={require("./images/04_about_us/04_about_us.jpg")} alt="" />
        </div>
       <div id='CONTACT_US' className='item'>
          <img src={require("./images/05_contact_us/05_contact_us.jpg")} alt="" />
          <div className='edit'>
            <h3>Name & SurName</h3>
            <input type="text" onChange={ (e) => setUserName(e.target.value)}/>
            <h3>Email</h3>
            <input type="text" onChange={ (e) => setEmail(e.target.value)} />
            <h3>Phone Number</h3>
            <input type="text" onChange={ (e) => setPhone(e.target.value)}/>
            <button id="myBtn" onClick={ handleClick}><img src={require("./images/05_contact_us/btn_contact.png")} alt="" /></button>
          </div>
          {/* <div className='editFooter'>
          <img src={require("./images/05_contact_us/contact_us.png")} alt="" />
          </div> */}
       </div>
       <div className='item fake'>
          <img src={require("./images/fake.png")} alt="" />
        </div>
      </div>
      
      {/* <!-- The Modal --> */}
      <div id="myModal" className="modal">
        {/* <!-- Modal content --> */}
        <div className="modal-content">
          <img src={require("./images/06_pop_up/popup_bg.png")} alt="" />
          <div className='popup-content'>
            <p className='title'>TIPS</p>
            <p className='message'>{errorMessage}</p>
            <button className='btn' onClick={ handleClose }>
              <img src={require("./images/06_pop_up/btn_sure.png")} alt="" />
              <div className='txt'>
                <p>Sure</p>
              </div>
            </button>
          </div>
          
            
        </div>
      </div>
    </div>
  );
}

export default App;
